<template>
    <crud-page :dialog="dialog" :titulo="item.id > 0 ? 'Editar Aluno' : 'Criar Aluno'" :valid="valid" :overlay="loading" @fechar="Close()" @salvar="Salvar()">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card flat>
                <v-card-text class="mt-2">
                    <v-row dense>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field v-model="item.matricula" label="Matricula" :rules="fieldRules" :counter="50" maxlength="50" dense outlined/>
                        </v-col>
                        <v-col cols="12" sm="10" md="7">
                            <v-text-field v-model="item.nome" label="Nome" :rules="fieldRules" :counter="125" maxlength="125" dense outlined/>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-switch color="primary" v-model="item.ativo" label="Ativo" style="margin-top: 2px" />
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete v-model="item.turmaId" label="Turma" item-value="id" item-text="nome" :items="turmas" dense outlined/>
                        </v-col>
                    </v-row>                            
                </v-card-text>
            </v-card>
        </v-form>
    </crud-page>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { CrudBase } from '@/core/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/shared/AlertService';
import { Aluno, Turma } from '@/core/models/geral';
import { AlunoService, TurmaService } from '@/core/services/geral';
import { applyPatch, createPatch } from 'rfc6902'

@Component
export default class CadastroAluno extends CrudBase{
    @Prop() item!: Aluno;
    @Prop() private value!: string;

    itemOriginal!: Aluno;
    service: AlunoService = new AlunoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    turmaService: TurmaService = new TurmaService();
    turmas: Turma[] = [];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = JSON.parse(JSON.stringify(this.item));
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.turmaService.ListarTudo().then(
            res => this.turmas = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = createPatch(this.itemOriginal, this.item);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
</style>