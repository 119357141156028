<template>
    <crud-page :dialog="dialog" :titulo="item.id > 0 ? 'Editar Registro de Ponto' : 'Criar Registro de Ponto'" :valid="valid" :overlay="loading" @fechar="Close()" @salvar="Salvar()">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card flat>
                <v-card-text class="mt-2">
                    <v-row dense>
                        <v-col cols="12" sm="12" md="6">
                            <v-autocomplete :disabled="!!item.id" v-model="item.alunoId" label="Aluno" :items="alunos" item-value="id" item-text="nome" :search-input.sync="onSearchAluno" :rules="fieldRules" dense outlined/>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field :disabled="!!item.id" label="Data/Hora Entrada" type="datetime-local" v-model="item.dataHoraEntrada" :rules="fieldRules" dense outlined/>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-text-field label="Data/Hora Saída" type="datetime-local" v-model="item.dataHoraSaida" dense outlined/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </crud-page>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import { CrudBase } from '@/core/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/shared/AlertService';
import { Aluno, RegistroPonto } from '@/core/models/geral';
import { AlunoService, RegistroPontoService } from '@/core/services/geral';
import { applyPatch, createPatch } from 'rfc6902'

@Component
export default class CadastroRegistroPonto extends CrudBase{
    @Prop() item!: RegistroPonto;
    @Prop() private value!: string;

    itemOriginal!: RegistroPonto;
    service: RegistroPontoService = new RegistroPontoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    onSearchAluno: any = null;
    isAlunoLoading: boolean = false;
    alunoService: AlunoService = new AlunoService();
    alunos: any[] = [];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = JSON.parse(JSON.stringify(this.item));

            this.alunos.push(this.item.aluno);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("onSearchAluno")
    SearchAluno(val: string){
        if(!(!!val) || !!this.item.alunoId || this.isAlunoLoading)
            return;
        this.isAlunoLoading = true;
        this.alunoService.AutoComplete(val).then(
            res => {
                this.alunos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isAlunoLoading = false;
        })
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = createPatch(this.itemOriginal, this.item);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
</script>

<style lang="scss">
    .v-card{
        border-radius: 5px !important;
    }
</style>