import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/home' },
  { path: '/home', name: 'Home', meta: { name: "Home"}, component: () => import('../views/shared/Home.vue') },
  { path: '/cadastro/turma', name: 'Turma', meta: { name: "Turma"}, component: () => import('../views/geral/Turma/Lista.vue') },
  { path: '/cadastro/aluno', name: 'Aluno', meta: { name: "Aluno"}, component: () => import('../views/geral/Aluno/Lista.vue') },
  { path: '/cadastro/registroPonto', name: 'RegistroPonto', meta: { name: "Registro de Pontos"}, component: () => import('../views/geral/RegistroPonto/Lista.vue') },
  { path: '/importacao', name: 'Importacao', meta: { name: "Importação"}, component: () => import('../views/geral/Importacao/Lista.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//PARA COLOCAR NO TITULO DA PAGINA O NOME ADICIONADO NA ROTA
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta ? `Fator Ponto - ${to.meta.name}` : "Fator Ponto";
  });
});

export default router
