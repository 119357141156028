<template>
    <div>
        <!-- IMAGEM CABEÇALHO -->
        <v-img class="headerImg"/>

        <!-- CABECALHO -->
        <div class="pageHeader">

            <v-btn class="menuButton" icon small color="white" depressed @click.prevent="drawer = !drawer" style="margin-right: 10px">
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            <v-spacer/>
            
            <!-- ACOES LISTA -->
            <div class="actions">
                <div style="color: white; font-size: 20px">
                    <v-icon color="white" style="margin-right: 10px; font-size: 28px !important">{{icone}}</v-icon>
                    <span>{{titulo}}</span> <br/>
                </div>
                <v-spacer/>
                <slot name="acoes"/>
            </div>
        </div>

        <!-- CONTEUDO -->
        <div class="pageContent">
            <v-card>
                <slot/>
            </v-card>
        </div>

        <!-- MENU LATERAL -->
        <v-navigation-drawer fixed v-model="drawer" temporary :width=" $vuetify.breakpoint.smAndDown ? '70vw' : '300px'">
            <div class="menuEmpresaLogo">                
                <img src="@/../public/img/icons/mstile-150x150.png" href="/home" style="height: 100px;">
            </div>
            <v-subheader class="ml-2 mt-1">MENU</v-subheader>
            <v-divider/>
            <v-list nav dense class="pa-0">
                <v-list-item to="/home" style="padding-left: 10px; border-radius: 10px">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item>
                <div v-for="(item, index) in geralMenuButtons" :key="index" style="padding: 2px 10px 2px 10px">
                    <v-list-item :to="item.path" :disabled="item.restrito" style="padding-left: 10px; border-radius: 10px">
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-icon><v-icon>{{item.icon}}</v-icon></v-list-item-icon>
                    </v-list-item>
                </div>
            </v-list>
            <template v-slot:append>
                <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 5px">
                    <span style="color: white; font-size: 12px"> Versão - {{GetAppVersion()}} </span>
                </div>
            </template>
        </v-navigation-drawer>

        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

    </div>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import { PageBase } from "@/core/shared";

@Component
export default class MasterPage extends PageBase{
    @Prop() public icone!: string;
    @Prop() public titulo!: string;
    @Prop({default: false}) public overlay!: boolean;

    drawer: boolean = false;
    geralMenuButtons: any[] = [];

    created(){
        this.CarregarMenu();
    }

    CarregarMenu(){
        this.geralMenuButtons = [
            { name:'Turmas', icon:'mdi-google-classroom', path:'/cadastro/turma', restrito: false },
            { name:'Alunos', icon:'mdi-account-group', path:'/cadastro/aluno', restrito: false },
            { name:'Registro Pontos', icon:'mdi-account-file', path:'/cadastro/registroPonto', restrito: false },
            { name:'Importação', icon:'mdi-import', path:'/importacao', restrito: false }
        ]
    }
}
</script>