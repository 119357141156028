import Vue from 'vue';

//MASTER
import MasterPage from "./shared/MasterPage.vue"

//SISTEMA
import CrudPage from "./shared/CrudPage.vue"
import TextSearch from "./shared/TextSearch.vue";
import ListFooter from "./shared/ListFooter.vue";

//CADASTROS
import CadastroAluno from "./geral/Aluno/Cadastro.vue"
import CadastroRegistroPonto from "./geral/RegistroPonto/Cadastro.vue"
import CadastroTurma from "./geral/Turma/Cadastro.vue"

//MASTER
Vue.component("master-page", MasterPage);

//SISTEMA
Vue.component("crud-page", CrudPage);
Vue.component("text-search", TextSearch);
Vue.component("list-footer", ListFooter);

//CADASTROS
Vue.component("cadastro-aluno", CadastroAluno);
Vue.component("cadastro-registroPonto", CadastroRegistroPonto);
Vue.component("cadastro-turma", CadastroTurma);