import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class RegistroPontoService extends Service {

    constructor() {
        super('v1/registroPonto');
    }

    public Registrar(matricula) {
        return http.get(`${this.GetNomeControle()}/Registrar`, {
            params: {
                matricula
            },
            headers: httpHeader.headers
        });
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';

            if(filter.alunoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `alunoId eq ${filter.alunoId}`
                console.log(paramTemp)
            }

            if(filter.turmaId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `aluno/turmaId eq ${filter.turmaId}`
            }

            if(filter.matricula){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `Contains(aluno/matricula, '${filter.matricula}')`
            }

            if(filter.dataHoraEntradaInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHoraEntrada ge ${new Date(filter.dataHoraEntradaInicial).toJSON()}`
            }

            if(filter.dataHoraEntradaFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHoraEntrada lt ${new Date(filter.dataHoraEntradaFinal).toJSON()}`
            }

            if(filter.dataHoraSaidaInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHoraSaida gt ${new Date(filter.dataHoraSaidaInicial).toJSON()}`
            }

            if(filter.dataHoraSaidaFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHoraSaida lt ${new Date(filter.dataHoraSaidaFinal).toJSON()}`
            }

            if (paramTemp) {
                if(parametros){
                    parametros += '&';
                }
                else {
                    parametros += '?';
                }
                parametros += `$filter=${paramTemp}`;
            }

        }

        return parametros;
    }

    public ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

}