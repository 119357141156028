import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme:{
    dark: true,
    default: 'light',
    options: {
      customProperties: true
    },
    themes:{
      dark:{
        primary: '#d66e00',
        menuActive: colors.blueGrey.lighten5,
        cadastroBackground: '#222222',
        chipGreen: colors.green.lighten3,
        chipFontGreen: colors.green.darken2,
        chipRed: colors.red.lighten3,
        chipFontRed: colors.red,
        chipBlue: colors.blue.lighten3,
        chipFontBlue: colors.blue.accent2,
        chipYellow: colors.amber.lighten4,
        chipFontYellow: colors.amber.accent4,
        chipLime: colors.lime.lighten3,
        chipFontLime: colors.lime.darken3,
        chipOrange: colors.orange.lighten3,
        chipFontOrange: colors.orange.lighten1,
        chipDarkGrey: '78787e',
        chipFontDarkGrey: '303032',
        chipGrey: 'c6c5cc',
        chipFontMidGrey: '6b6b71',
        chipFontGrey: colors.grey.darken4,
        linkColor: colors.blue.accent4,
        canceladoRed: colors.red.lighten3
      }
    }
  }
});
