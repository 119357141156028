import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";
import { AlunoImportacaoModel } from "@/core/models/geral/model";

export class ImportacaoService extends Service {

    constructor() {
        super('v1/importacao');
    }

    public Importacao(model: AlunoImportacaoModel[]) {
        return http.post(`${this.GetNomeControle()}/Importacao`, model);
    }
}